import { API, asyncWrap } from '../../api';
import { parseNumber, utils } from '../../helpers';
import { EntryMap } from '../../types';
import { ImageDimensions } from '../LegacyMediaSelector';

export type MediaUploaderOptions = {
	type?: "image" | "audio";
} & (ImageOptions | AudioOptions);

type ImageOptions = {
	type?: "image";
	maxQuantity?: number;
	multiple?: boolean;
	delay?: number;
	minimumDPI?: {
		dpi: number;
		realSize: string; //0x0
		matchLenWidthRatio?: boolean;
	};
	maxSize?: number;
	source?: string;
	restrictTypes?: ImageRestrictTypes;
	dimensions?: ImageDimensions | ImageDimensions[];
	maxDimensions?: ImageDimensions;
	minDimensions?: ImageDimensions;
};

type AudioOptions = {
	type: "audio";
	maxQuantity?: number;
	multiple?: boolean;
	source?: string;
	delay?: number;
	restrictTypes?: AudioRestrictTypes;

	maxMB?: number;
	maxDuration?: number;
};

export interface ImageRestrictTypes {
	"image/apng"?: boolean;
	"image/avif"?: boolean;
	"image/gif"?: boolean;
	"image/jpeg"?: boolean;
	"image/png"?: boolean;
	"image/svg+xml"?: boolean;
	"image/webp"?: boolean;
}
export interface AudioRestrictTypes {
	"audio/wav"?: boolean;
	"audio/mp3"?: boolean;
}

export const isAudioType = (type: string) => ["wav", "mp3", "audio/mpeg"].some((t) => type.includes(t));
export const isImageType = (type: string) => ["png", "jpg", "jpeg", "gif", "svg", "webp", "avif", "apng"].some((t) => type.includes(t));
export interface MediaFileMetadata {
	id?: string;
	name?: string;
	internalName?: string
	description?: string;
	uploaderName?: string;
	uploader?: string;
	url?: string;
	source?: string;
	type?: string;
	tags?: string[];
	created?: number;
	updated?: number;
	size?: number;

	height?: number;
	width?: number;
	duration?: number;

	key?: string;
}

type MediaUploadResponse = {
	response: MediaFileMetadata[];
	valid: boolean
};

export const defaultImageOptions = {
	type: "image",
	multiple: true,
	delay: 500,
} as const;

export const defaultAudioOptions = {
	type: "audio",
} as const;

type ValidationResponse = {
	isValid: boolean;
	extra: any[]; // Array of objects which should be attached/merged into the response
}

export const handleMediaUpload = async (files: File | File[], fileMetadata: EntryMap<MediaFileMetadata>, cfg: MediaUploaderOptions): Promise<MediaUploadResponse> => {
	const config: MediaUploaderOptions = ({ ...(!cfg?.type || cfg?.type === "image" ? defaultImageOptions : defaultAudioOptions), ...cfg } as any);

	const filesArray: File[] = normalizeToFileArray(files);

	// vaildate all the files names
	for (let i = 0; i < filesArray.length; i++) {
		const file = filesArray[i];

		const { cleaned, valid } = isFileNameInvalid(file.name, config)
		if (!valid) return { response: [], valid: false };

		if (cleaned !== file.name) {
			filesArray[i] = new File([file], cleaned, { type: file.type, lastModified: file.lastModified });
		}

	}

	const validateFuncs = {
		image: validateImages,
		audio: validateAudio,
	};

	const { isValid, extra } = (await validateFuncs?.[config?.type || "image"](filesArray, config as any, true)) || false;
	if (!isValid) return { response: [], valid: false };

	if (config.delay) await utils.sleep(config.delay);

	let error = false;
	const response = await asyncWrap(() => uploadMedia(filesArray, fileMetadata, config), {
		errorCallback: (err) => error = true,
	}) || [];
	if (error) {
		return { response: [], valid: false };
	}
	utils.notify("Success", `Uploaded ${filesArray.length} ${cfg?.type || "image"} file${filesArray.length > 1 ? "s" : ""}!`);

	// IF type is audio we loop over the response and add the duration to the metadata
	if (extra.length) {
		for (let i = 0; i < response.length; i++) {
			const metaItem = response[i];
			metaItem.duration = extra[i]?.duration || 0;
		}
	}

	if (utils.isStaging()) {
		for (let i = 0; i < response.length; i++) {
			const metaItem = response[i];
			metaItem.url = metaItem.url?.replace("cdn.aiqstaging", "lab.aiqstaging") ?? '';
		}
	}

	return { response, valid: true };
};

export const validateImages = async (images: File[], config: ImageOptions, isUpload: boolean = false): Promise<ValidationResponse> => {
	const failedResponse = { isValid: false, extra: [] };
	if (!images.length) {
		utils.showErr("You must select an image to upload");
		return failedResponse;
	}

	if (!config.multiple && images.length !== 1) {
		utils.showErr("You can only upload one image at a time");
		return failedResponse;
	}

	if (config.maxQuantity && images.length > config.maxQuantity) {
		utils.showErr(`You can only upload a maximum of ${config.maxQuantity} images at a time`);
		return failedResponse;
	}

	for (const image of images) {
		if (isTypeRestricted(image.type, config)) {
			utils.showErr(`Image type (${image.type}) is not accepted.`);
			return failedResponse;
		}

		if (exceedsMaxSizeImage(image.size, config)) {
			utils.showErr(`Image is too large. Max size is ${config.maxSize}KB`);
			return failedResponse;
		}

		if (config.dimensions || config.maxDimensions || config.minDimensions || config.minimumDPI) {
			if (!(await validateImageDimensionsAndDPI(image, config, isUpload))) return failedResponse;
		}

	}

	return { isValid: true, extra: [] }
};

export const validateAudio = async (audio: File[], config: AudioOptions, isUpload?: boolean): Promise<ValidationResponse> => {
	const failedResponse = { isValid: false, extra: [] };
	const extra = [];

	if (!audio.length) {
		utils.showErr("You must select an audio file to upload");
		return failedResponse;
	}

	if (!config.multiple && audio.length !== 1) {
		utils.showErr("You can only upload one audio file at a time");
		return failedResponse;
	}

	if (config.maxQuantity && audio.length > config.maxQuantity) {
		utils.showErr(`You can only upload a maximum of ${config.maxQuantity} audio files at a time`);
		return failedResponse;
	}

	for (let i = 0; i < audio.length; i++) {
		const file = audio[i];
		// Check file size (5MB = 5 * 1024 * 1024 bytes)
		if (exceedsMaxSizeAudio(file.size, config)) {
			utils.showErr(`Audio file is too large. Max size is ${config.maxMB}MB`);
			return failedResponse
		}

		if (config.maxDuration) {
			// Check audio duration
			const duration = await getAudioDuration(file);

			extra[i] = { name: file.name, size: file.size };
			if (duration > config.maxDuration) {
				utils.showErr(`Audio file is too long. Max duration is ${config.maxDuration} seconds`);
				return failedResponse
			}

		}

	}

	return { isValid: true, extra: [] };
};

const exceedsMaxSizeAudio = (audioSize: number, config: AudioOptions) => {
	return config.maxMB && audioSize > config.maxMB * 1024 * 1024;
}

const normalizeToFileArray = (files: FileList | File | File[]): File[] => {
	return !Array.isArray(files) && !(files instanceof FileList) ? [files] : (files as File[]);
};

const uploadMedia = async (filesArray: File[], fileMetadata: EntryMap<MediaFileMetadata>, config: MediaUploaderOptions): Promise<MediaFileMetadata[]> => {
	if (config.type === "audio") {
		for (let i = 0; i < filesArray.length; i++) {
			const file = filesArray[i], fileMeta = fileMetadata[file.name];
			if (!(fileMeta)?.duration) {
				const duration = await getAudioDuration(file);
				fileMetadata[file.name] = { ...(fileMetadata[file.name] || {}), duration };
			}
		}
	}

	if (config.type === "image") {
		for (let i = 0; i < filesArray.length; i++) {
			const file = filesArray[i], fileMeta = fileMetadata[file.name];
			if (!(fileMeta)?.width || !(fileMeta)?.height) {
				const { width, height } = await utils.loadImageDimensionsFromFile(file);
				fileMetadata[file.name] = { ...(fileMetadata[file.name] || {}), width, height };
			}
		}
	}

	return API.uploadUserMedia({
		files: filesArray,
		fileMetadata,
		config,
	});
};

const isTypeRestricted = (imageType: string, config: MediaUploaderOptions): boolean => {
	if (!config.restrictTypes) return false;
	let blacklist = false;
	const acceptedTypes = Object.keys(config.restrictTypes || {})
		.filter((type) => {
			const value = !!config.restrictTypes?.[type as keyof MediaUploaderOptions["restrictTypes"]]
			if (value === false) blacklist = true;
			return value
		});
	return blacklist ? !acceptedTypes.includes(imageType) : acceptedTypes.includes(imageType);
};

const exceedsMaxSizeImage = (imageSize: number, config: ImageOptions) => {
	return config.maxSize && imageSize > config.maxSize * 1024;
};

const validateImageDimensionsAndDPI = async (image: File & MediaFileMetadata, config: ImageOptions, isUpload: boolean) => {
	const validation = ({ height, width }: any) => {
		if (isImageDimensionsInvalid(width, height, config)) return false;
		if (isImageDPIInvalid(width, height, config)) return false;
		return true;
	};

	const hasWidthAndHeight = !(image as any).width && !!(image as any).height;

	if (isUpload) {
		const { width, height } = await utils.loadImageDimensionsFromFile(image as File);
		image.width = width;
		image.height = height;
		return validation({ width, height });
	} else if (!hasWidthAndHeight) {
		const { width, height } = await utils.loadImageDimensionsFromURL(((image as MediaFileMetadata).url) || "");
		image.width = width;
		image.height = height;
		return validation({ width, height });
	} else {
		return validation(image as any)
	}
};

const isImageDPIInvalid = (width: number, height: number, config: ImageOptions): boolean => {
	if (config.minimumDPI) {
		const [heightIn, widthIn] = config.minimumDPI.realSize.split("x").map(Number);
		const realWidth = widthIn + 0.25, realHeight = heightIn + 0.25; // Including the offset for direct mail postcards
		const dpiW = width / realWidth, dpiH = height / realHeight;

		if (dpiW < config.minimumDPI.dpi || dpiH < config.minimumDPI.dpi) {
			utils.showErr(`Your image must be a minimum of ${config.minimumDPI.dpi} DPI`);
			return true;
		}

		const realRatio = realWidth / realHeight, imgRatio = width / height;
		if (realRatio !== imgRatio && config.minimumDPI.matchLenWidthRatio) {
			utils.showErr(`Your image must match the width/height ratio of the post-card. Expected: ${realRatio.toFixed(2)}. Yours: ${imgRatio.toFixed(2)}`);
			return true;
		}
	}

	return false;
};

const isImageDimensionsInvalid = (width: number, height: number, config: ImageOptions): boolean => {
	// Check for minimum dimensions
	const configMinWidth = parseNumber(config.minDimensions?.width),
		configMinHeight = parseNumber(config.minDimensions?.height);
	if (config.minDimensions && (width < configMinWidth || height < configMinHeight)) {
		utils.showErr(`Wrong image dimensions. Minimum are: ${configMinWidth}x${configMinHeight} px`);
		return true;
	}

	// Check for maximum dimensions
	const configMaxWidth = parseNumber(config.maxDimensions?.width),
		configMaxHeight = parseNumber(config.maxDimensions?.height);
	if (config.maxDimensions && (width > configMaxWidth || height > configMaxHeight)) {
		utils.showErr(`Wrong image dimensions. Maximum are: ${configMaxWidth}x${configMaxHeight} px`);
		return true;
	}

	// Check for specific dimensions if they're an array
	if (config.dimensions && Array.isArray(config.dimensions)) {
		const found = config.dimensions.find((d) => d.width === width && d.height === height);
		if (!found) {
			const allowedDimensions = config.dimensions.map((d) => `${d.width}x${d.height}`).join(", ");
			utils.showErr(`Wrong image dimensions. Allowed are: ${allowedDimensions} px`);
			return true;
		}
	}

	return false;
};

const isFileNameInvalid = (fileName: string, config: MediaUploaderOptions): { cleaned: string, valid: boolean } => {
	const invalidChars = ["'", '"'];
	const invalidEndChars = [")"];

	let cleaned = fileName.trim();
	if (fileName.includes(' ')) cleaned = cleaned.replace(/\s/g, "_");
	const match = cleaned.match(/\(\d+\)/);
	if (match) {
		const num = match[0].replace(/\D/g, "");
		cleaned = cleaned.replace(match[0], num);
	}
	if (invalidChars.some((char) => fileName.includes(char))) {
		utils.showErr(`Invalid file name: ${fileName}. Please remove any spaces, quotes, or special characters. (e.g. ${invalidChars.join(", ")})`);
		return { cleaned, valid: false };
	}
	if (invalidEndChars.some((char) => fileName.endsWith(char))) {
		utils.showErr(`Invalid file name: ${fileName}. Please remove invalid end characters. (e.g. ${invalidEndChars.join(", ")})`);
		return { cleaned, valid: false };
	}
	return { cleaned, valid: true };
};

type AudioInput = File | string;

export const getAudioDuration = (input: AudioInput): Promise<number> => {
	// Primary method using the Audio object
	const getDurationUsingAudioObject = (src: string): Promise<number> => {
		return new Promise((resolve, reject) => {
			const audio = new Audio();
			audio.src = src;

			audio.addEventListener("canplaythrough", () => {
				if (audio.duration && audio.duration !== Infinity) {
					resolve(audio.duration);
				} else {
					reject(new Error("Audio duration is Infinity."));
				}
			});

			audio.addEventListener("error", () => {
				reject(new Error("Failed to load audio file using Audio object."));
			});
		});
	};

	// Fallback method using the Web Audio API
	const getDurationUsingWebAudio = (audioData: ArrayBuffer): Promise<number> => {
		return new Promise((resolve, reject) => {
			const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();

			audioContext.decodeAudioData(audioData, (buffer) => {
				resolve(buffer.duration);
			}, (error) => {
				reject(new Error("Failed to decode audio file."));
			});
		});
	};

	return new Promise((resolve, reject) => {
		if (typeof input === 'string') {
			// For URL
			getDurationUsingAudioObject(input)
				.then(resolve)
				.catch(reject);
		} else {
			// For File
			const reader = new FileReader();
			reader.readAsArrayBuffer(input);

			reader.onload = (event: any) => {
				getDurationUsingAudioObject(URL.createObjectURL(input))
					.then(resolve)
					.catch(() => {
						getDurationUsingWebAudio(event.target.result as ArrayBuffer).then(resolve).catch(reject);
					});
			};

			reader.onerror = (error) => {
				reject(new Error("Failed to read the file."));
			};
		}
	});
};

export function formatFileSize(bytes: number): string {
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
	if (bytes === 0) return '0 Byte';
	const i = parseInt((Math.floor(Math.log(bytes) / Math.log(1024)) as any), 10);
	if (i === 0) return `${bytes} ${sizes[i]}`;
	return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`;
}